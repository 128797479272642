import App from './App'
import router from './router/index'
import store from './store/index'
import prototypeExpand from "@/basic/prototypeExpand"
import preventReClick from "@/basic/directive"
import TTKGameUI from "@/lib/src/index.js"
Vue.use(preventReClick)
Vue.use(prototypeExpand)
import './permission'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$name = "北京子默网络科技有限公司"
Vue.prototype.$setStrVal = (text, data = {}) => {
  if (!text) {
    return ""
  }
  for( let i in data) {
    const regExp = new RegExp("\\$\\{"+i+"\\}", "g")
    text = text.replace(regExp, data[i])
  }
  return text
}

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')